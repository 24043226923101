import React, {Component} from 'react';
import '../Css/App.css';
import Axe from './Axe';
import EmptyBlockMeteo from '../Images/EmptyBlockMeteo.png';

class MiniBlockMeteo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      meteo: null,
      trend: null,
      previousPeriod: null,
      comingPeriod: null,
      sponsorFeedback: null,
      labelPrevious: null,
      labelComing: null,
      labelSponsor: null,
      emptyBlock: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);
      
    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let meteo, trend, previousPeriod, comingPeriod, sponsorFeedback, labelPrevious, labelComing, labelSponsor, emptyBlock;

    if(blockContent) {
      // Header
      if(blockContent.Headers) {
        if(blockContent.Headers.find(element => element.FieldName === 'Meteo')) {
          meteo = JSON.parse(blockContent.Headers.find(element => element.FieldName === 'Meteo').FieldValue);
        }
        if(blockContent.Headers.find(element => element.FieldName === 'Trend')) {
          trend = JSON.parse(blockContent.Headers.find(element => element.FieldName === 'Trend').FieldValue);
        }
        if(blockContent.Headers.find(element => element.FieldName === 'Previous_Period')) {
          previousPeriod = blockContent.Headers.find(element => element.FieldName === 'Previous_Period').FieldValue;
          labelPrevious = blockContent.Headers.find(element => element.FieldName === 'Previous_Period').FieldLabel;
        }
        if(blockContent.Headers.find(element => element.FieldName === 'Coming_Period')) {
          comingPeriod = blockContent.Headers.find(element => element.FieldName === 'Coming_Period').FieldValue;
          labelComing = blockContent.Headers.find(element => element.FieldName === 'Coming_Period').FieldLabel;
        }
        if(blockContent.Headers.find(element => element.FieldName === 'Sponsor_Feedback')) {
          sponsorFeedback = blockContent.Headers.find(element => element.FieldName === 'Sponsor_Feedback').FieldValue;
          labelSponsor = blockContent.Headers.find(element => element.FieldName === 'Sponsor_Feedback').FieldLabel;
        }
        if(blockContent.Headers.find(element => element.FieldName === 'EmptyBlock')) {
          emptyBlock = blockContent.Headers.find(element => element.FieldName === 'EmptyBlock').FieldValue;
        }
      }

      this.setState({ meteo, trend, previousPeriod, comingPeriod, sponsorFeedback, labelPrevious, labelComing, labelSponsor, emptyBlock });
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, blockType, blockLabel, blockContent, meteo, trend, previousPeriod, comingPeriod, sponsorFeedback, labelPrevious, labelComing, labelSponsor, emptyBlock } = this.state;

    return (
      <div className="miniblock">
        {(meteo || trend) && <div className="miniblockMeteo scrollbar-miniblock">
          {/* Previous Period */}
          {previousPeriod && <div className="pt10">
            {/* <p className="miniblockText">{previousPeriod}</p> */}
            <div className="miniblockText" dangerouslySetInnerHTML={{ __html: previousPeriod }}/>
            <span className="miniblockLabel">{labelPrevious}</span>
          </div>}
          {/* Coming Period */}
          {comingPeriod && <div className="pt20">
            {/* <p className="miniblockText">{comingPeriod}</p> */}
            <div className="miniblockText" dangerouslySetInnerHTML={{ __html: comingPeriod }}/>
            <span className="miniblockLabel">{labelComing}</span>
          </div>}
          {/* Sponsor Feedboack */}
          {sponsorFeedback && <div className="pt20">
            {/* <p className="miniblockText">{comingPeriod}</p> */}
            <div className="miniblockText" dangerouslySetInnerHTML={{ __html: sponsorFeedback }}/>
            <span className="miniblockLabel">{labelSponsor}</span>
          </div>}
        </div>}
        {/* Empty Block */}
        {/* {!meteo && !trend && <div className="miniblockEmpty scrollbar-miniblock">
          <div className=""><img className="iconsEmptyBlock" src={EmptyBlockMeteo} alt="EmptyBlock Meteo"/></div>
          <div className=""><div className="miniblockEmptyText" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div>
          </div>
        </div>} */}
        {/* Footer */}
        {(meteo || trend) && <div className="miniblockFooter">
          {/* Meteo & Trend icons */}
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 center"><Axe Axe={'Meteo'} Value={meteo} View={'Miniblock'}></Axe></div>
            <div className="col-xs-6 col-sm-6 col-md-6 center"><Axe Axe={'Trend'} Value={trend} View={'Miniblock'}></Axe></div>
          </div>
        </div>}
      </div>
    )
  }
}

export default MiniBlockMeteo;